export class UserAuth {
	public siteID = 0;
	public siteName = "";
	public siteImage = "";
	public userID = 0;
	public authSiteUserID = "";
	public firstName = "";
	public lastName = "";
	public userName = "";
	public imageURL = "";
	public email = "";
	public token = "";
	public secret = "";
	public expiration = "";
}
