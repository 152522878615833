<template>
	<div class="screen">
		<div class="cleverHeader">
			<img class="icon" src="/img/icon-white.png" />
		</div>
		<div class="fail">{{ errorMessage }}</div>
		<div class="signIn">Choose a sign-in option:</div>
		<div class="widget" id="firebaseui-auth-container"></div>
		<!-- <div class="signIn">To sign up or in, enter your user name below</div>
		<div class="error">{{ errorMessage }}</div>
		<input
			type="text"
			class="username"
			v-model="userName"
			placeholder="User Name"
		/>
		<button class="btn" @click="doLogin()">Next</button> -->
	</div>
</template>

<script>
import {Auth} from "@/providers/auth";
import {UserAuth} from "@/models/userAuth.model";
// import { Plugins } from '@capacitor/core';
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import * as firebaseui from "firebaseui";

export default {
	name: "Login",
	data() {
		return {
			authService: new Auth(),
			errorMessage: "",
			ui: null,
			userName: ""
		};
	},
	mounted() {
		// console.log("Starting...");
		this.showLogin();
	},
	methods: {
		showLogin: async function () {
			console.log("Show login");
			const uiConfig = {
				tosUrl: "http://cleversystems.com/privacy.html",
				privacyPolicyUrl: "http://cleversystems.com/privacy.html",
				signInFlow: "popup",
				signInOptions: [firebase.default.auth.GoogleAuthProvider.PROVIDER_ID],
				callbacks: {
					signInSuccessWithAuthResult: function (authResult) {
						console.log("SUCCESS!!!!!!!", authResult)
						const authService = new Auth();
						const user = firebase.default.auth().currentUser;
						console.log("User is", user);
						user
							.getIdToken(/* forceRefresh */ true)
							.then(function (idToken) {
								const userAuth = new UserAuth();
								userAuth.authSiteUserID = user.uid;
								userAuth.token = idToken;
								userAuth.userName = user.displayName;
								userAuth.imageURL = user.photoURL;
								userAuth.email = user.email;
								userAuth.firstName = authResult.additionalUserInfo.profile.given_name;
								userAuth.lastName = authResult.additionalUserInfo.profile.family_name;
								console.log("userAuth is", userAuth);
								authService.login(userAuth).then(res => {
									console.log("Got back from login, save token, then redirect to loading", res.user);
									localStorage.setItem("token", res.token);
									if (res.user.status == "newbie") {  // And check for no groups or questions.
										console.log("Go to the new user page!!!", res.user)
										window.location.href = "new-user";
									} else {
										console.log("Existing user, go to next choice.")
										window.location.href = "choice/next";
									}
								});
							})
							;
						return false; // Do not redirect
					},
					uiShown: function () {
						// document.getElementById("loader").style.display = "none";
					}
				}
			};
			console.log("About to start with this config", uiConfig);
			if (!this.ui) {
				this.ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.default.auth());
				this.ui.start("#firebaseui-auth-container", uiConfig);
			}
		},
		hash: function (str) {
			let hash = 0, i, chr;
			for (i = 0; i < str.length; i++) {
				chr = str.charCodeAt(i);
				hash = ((hash << 5) - hash) + chr;
				hash |= 0; // Convert to 32bit integer
			}
			return hash;
		}
	}
};
</script>

<style scoped>
.screen {
	text-align: center;
}
.cleverHeader {
	text-align: center;
}

.icon {
	margin-top: 10px;
	width: 30vw;
}

.cleverName {
	font-size: 36px;
}

.signIn {
	margin: 10px;
	text-align: center;
	font-size: 26px;
}

.widget {
	background-color: black;
	margin: 5px;
}
.fail {
	color: red;
}
.error {
	color: red;
	margin-bottom: 10px;
}

.username {
	font-size: 18px;
	padding: 5px;
	text-align: center;
	margin-right: 7px;
	width: 150px;
	color: black;
}

.btn {
	font-size: 18px;
	background-color: black;
	color: #6699ff;
	padding: 5px;
	border: 1px solid #6699ff;
}
</style>
