<template>
	<div id="app">
		<router-view />
		<tabs v-if="$services.enableNav" />
	</div>
</template>

<script>
import {Auth} from "@/providers/auth";
import Tabs from '@/components/Tabs.vue'
// import { Plugins, StatusBarStyle } from '@capacitor/core';

export default {
	name: "Login",
	components: {
		Tabs
	},
	data() {
		return {
			authService: new Auth(),
			errorMessage: ""
		};
	},
	created() {
		// const { StatusBar } = Plugins;
		// StatusBar.setStyle({ style: StatusBarStyle.Dark });
		// StatusBar.show()
	},
	mounted() {
		this.$services.token = localStorage.getItem("token");
		this.authService.checkAuthentication(this.$services.token).then((res) => {
			this.$services.getMe().then(me => {
				console.log("GOT ME IN APP.vue")
				this.$services.enableNav = true;
				this.$forceUpdate(); // to call computed properties
				if (me.newKey) {
					// console.log("USER HAS A NEW KEY....SEND THEM TO THE KEY INFO PAGE.")
					this.$router.push(`/key-info/${me.newKey}?isNew=true`);
				}
			});
		}, (err) => {
			// console.log("Not authorized. Send to login.");
			this.$services.enableNav = false;
			this.$router.push('/login')
		});
	}
};

</script>

<style>
#app {
	font-family: "Roboto Condensed", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: black;
	color: white;
	padding: 0px 2px 0px 2px;
	overflow: scroll;
	height: 100%;
	width: 100%;
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	left: 0;
}

body {
	font-family: "Archivo Narrow";
	background-color: "transparent";
}

.page {
	background-color: black;
	color: white;
	height: 100vh;
}

.coverup {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 5;
	background-color: black;
	color: white;
	padding-right: 15px;
}

.blackout {
	background-color: black;
}

.box {
	justify-content: center;
	text-align: center;
}

.title {
	display: inline-block;
	font-size: 22px;
	background-color: black;
	padding: 2px;
	white-space: "pre-line";
	vertical-align: top;
}

.bigScore {
	display: inline-block;
	padding-top: 4px;
	font-size: 20px;
}

.sectionHeader {
	font-size: 22px;
	padding-left: 3px;
}

.goButton {
	display: block;
	color: white;
	cursor: pointer;
	margin: 2px 2vw 0px 2vw;
	font-size: 24px;
	padding: 8px;
	border-radius: 5px;
	background-color: #00b300;
	border: 2px black solid;
	text-align: center;
	text-shadow: 1px 1px black;
	box-shadow: 0 10px 10px -10px #ffffff inset;
	width: 96vw;
	height: 48px;
}
.goButton:disabled {
	background-color: #005f00;
	color: #bbbbbb;
}
.divider {
	display: block;
	width: 100%;
	font-size: 24px;
	text-align: center;
	margin-bottom: 5px;
	border-top: 1px solid white;
	border-bottom: 1px solid white;
}
.feedName {
	border-top: 1px solid silver;
	border-bottom: 1px solid silver;
	display: inline-block;
	font-size: 20px;
	font-weight: 600;
	margin-left: 3px;
	margin-bottom: 5px;
}
.feedScore {
	display: inline-block;
	font-size: 18px;
}
.infRow {
	margin: 3px;
}
.infHeader {
	font-size: 20px;
}
.infScore {
	display: inline-block;
	font-size: 20px;
}

.admin {
	background-color: #e85252;
}

.good {
	color: #00cc00;
}

.bad {
	color: #c83232;
}

.right {
	float: right;
	padding-right: 3px;
}

.left {
	float: left;
}

.center {
	float: center;
}

.footer {
	position: fixed;
	left: 0px;
	right: 0px;
	margin: 0px;
	background: black;
	color: white;
	padding-bottom: 5px;
	bottom: 60px;
	width: 100vw;
	height: 57px;
	z-index: 4;
}

.footer-spacer {
	height: 50px;
	bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
	bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
}
.bottom-spacer {
	margin-bottom: constant(safe-area-inset-top); /* iOS 11.0 */
	margin-bottom: env(safe-area-inset-top); /* iOS 11.2 */
	height: 80px;
}
</style>
