import axios from 'axios';
import {User} from '@/models/user.model'

export class Services {
	public baseURL = "https://api.cleversystem.com/";
	// private baseURL = "http://localhost:8080/";
	// public baseURL = "http://localhost:5001/clever-1362/us-central1/api/"
	// public baseURL = "https://us-central1-clever-1362.cloudfunctions.net/api/"
	public token = "<empty>";
	public me = new User();
	public enableNav = false;

	hasKey(key: string) {
		// return true;
		return this.me && this.me.keys && this.me.keys.includes(key);
	}

	headers() {
		return {headers: {'Content-Type': 'application/json', 'Authorization': this.token}}
	}

	// //----------My Info
	async getMe() {
		this.me = (await axios.get(this.baseURL + `users/me`, this.headers())).data;
		return this.me;
	}

	//----------Add Invites
	async addInvites(groupID: any) {
		return (await axios.get(this.baseURL + `groups/${groupID}/invites/add/`, this.headers())).data;
	}

	//----------Accept Invite
	async acceptInvite(code: string) {
		return (await axios.post(this.baseURL + `users/me/acceptInvite/`, {code: code}, this.headers())).data;
	}

	//----------Group User Scores
	async groupUserScores(groupID: any) {
		return (await axios.get(this.baseURL + `groups/` + groupID + "/userScores/", this.headers())).data;
	}

	//----------Group Subscription Info
	async getGroupSubscription(groupID: any, feedID: any) {
		return (await axios.get(this.baseURL + `groups/` + groupID + "/subscription/" + feedID, this.headers())).data;
	}

	async updateGroupSubscription(subscription: any) {
		return (await axios.post(this.baseURL + `groups/` + subscription.groupID + "/subscription/" + subscription.feedID, subscription, this.headers())).data;
	}

	//----------Add Key
	async addKey(name: string) {
		return (await axios.post(this.baseURL + `users/me/keys`, {name: name}, this.headers())).data;
	}

	//----------Use Key
	async useKey(key: any) {
		return (await axios.put(this.baseURL + `users/me/keys`, {key: key}, this.headers())).data;
	}

	//----------Make Choice
	async makeChoice(expectation: any) {
		return (await axios.post(this.baseURL + `choices/` + expectation.choiceID + "/expectation", {expectation: expectation}, this.headers())).data;
	}

	//----------General Purpose API Services
	async get(route: string) {
		return (await axios.get(this.baseURL + route, this.headers())).data;
	}

	async getOne(route: string, id: any) {
		return (await axios.get(this.baseURL + route + `/` + id, this.headers())).data;
	}

	async getAll(route: string) {
		return (await axios.get(this.baseURL + route, this.headers())).data;
	}

	async createOne(route: string, data: any) {
		return (await axios.post(this.baseURL + route, data, this.headers())).data;
	}

	async updateOne(route: string, id: any, data: any) {
		return (await axios.put(this.baseURL + route + `/` + id, data, this.headers())).data;
	}

	async deleteOne(route: string, id: any) {
		return (await axios.delete(this.baseURL + route + `/` + id, this.headers())).data;
	}

}
