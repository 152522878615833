import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

Vue.use(VueRouter)

const routes = [
	{path: '/', name: 'Home', component: () => import('../views/Choice.vue')},
	{path: '/actor/:id', name: 'Actor', component: () => import('../views/Actor.vue')},
	{path: '/choice/:id', name: 'Choice', component: () => import('../views/Choice.vue')},
	{path: '/choice-list', name: 'ChoiceList', component: () => import('../views/ChoiceList.vue')},
	{path: '/date/:id', name: 'Date', component: () => import('../views/Date.vue')},
	{path: '/edit-group/:id', name: 'EditGroup', component: () => import('../views/EditGroup.vue')},
	{path: '/group/:id', name: 'Group', component: () => import('../views/Group.vue')},
	{path: '/group/:id/chat', name: 'Group', component: () => import('../views/GroupChat.vue')},
	{path: '/influencer/:id', name: 'Influencer', component: () => import('../views/Influencer.vue')},
	{path: '/key-info/:id', name: 'KeyInfo', component: () => import('../views/KeyInfo.vue')},
	{path: '/login', name: 'Login', component: Login},
	{path: '/news', name: 'News', component: () => import('../views/News.vue')},
	{path: '/new-user', name: 'NewUser', component: () => import('../views/NewUser.vue')},
	{path: '/no-choices', name: 'NoChoices', component: () => import('../views/NoChoices.vue')},
	{path: '/score', name: 'Score', component: () => import('../views/Score.vue')},
	{path: '/settings', name: 'Settings', component: () => import('../views/Settings.vue')},
	{path: '/trends', name: 'Trends', component: () => import('../views/Trends.vue')}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.afterEach((to, from) => {
	// TODO: Fix analytics
	// firebase.analytics().logEvent(`view:${to.name || '/'}`);
})

export default router
