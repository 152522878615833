import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { Services } from '@/providers/services'
import moment from 'moment'

import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD8NaEohOBQ329NPdzFn-_TcWo81VU4Nv4",
  authDomain: "clever-1362.firebaseapp.com",
  databaseURL: "https://clever-1362.firebaseio.com",
  projectId: "clever-1362",
  storageBucket: "clever-1362.appspot.com",
  messagingSenderId: "381304584260",
  appId: "1:381304584260:web:c7a233ada5fa13b76fa411",
  measurementId: "G-YJBN1BZ3PG"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.config.productionTip = false;

Vue.filter('short_date', function (value: any) {
	if (!value) return ''
	const today = new Date();
	const cutoffDate = new Date(value);
	today.setHours(0, 0, 0, 0);
	cutoffDate.setHours(0, 0, 0, 0);
	if (cutoffDate.toDateString() == today.toDateString()) { // Today
		return moment(value).format('h:mma')
	} else {
		if (cutoffDate.getFullYear() == today.getFullYear()) {
			return moment(value).format('M/D h:mma')
		} else {
			return moment(value).format('M/D/YY h:mma')
		}
	}
})

Vue.filter('just_date', function (value: any) {
	if (!value) return ''
	const today = new Date();
	const cutoffDate = new Date(value);
	today.setHours(0, 0, 0, 0);
	cutoffDate.setHours(0, 0, 0, 0);
	if (cutoffDate.toDateString() == today.toDateString()) { // Today
		return moment(value).format('h:mma')
	} else {
		if (cutoffDate.getFullYear() == today.getFullYear()) {
			return moment(value).format('M/D')
		} else {
			return moment(value).format('M/D/YY')
		}
	}
})

Vue.filter('tiny_date', function (value: any) {
	if (!value) return ''
	return moment(value).format('M/D')
})

Vue.filter('long_date', function (value: any) {
	if (!value) return ''
	return moment(value).format('MMMM D, YYYY')
})

Vue.prototype.$eventHub = new Vue(); // Global event hub for component-to-component events
Vue.prototype.$services = new Services(); // Global services hub
Vue.prototype.$analytics = firebase.analytics();

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
