export class User {
	public id = 0;
	public firstname = "";
	public lastname = "";
	public username = "";
	public imageURL = "";
	public about = "";
	public email = "";
	public joinCode = "";
	public role = "";
	public status = "";
	public keys: string[] = [];
	public newKey = "";
	public createdAt: Date = new Date();
}
