import axios from 'axios';

export class Auth {
	// public baseURL = "http://localhost:8080/";
	public baseURL = "https://api.cleversystem.com/";
	// public baseURL = "http://localhost:5001/clever-1362/us-central1/api/"
	// public baseURL = "https://us-central1-clever-1362.cloudfunctions.net/api/"
	public firebaseConfig = {
		apiKey: "AIzaSyD8NaEohOBQ329NPdzFn-_TcWo81VU4Nv4",
		authDomain: "clever-1362.firebaseapp.com",
		databaseURL: "https://clever-1362.firebaseio.com",
		projectId: "clever-1362",
		storageBucket: "clever-1362.appspot.com",
		messagingSenderId: "381304584260",
		appId: "1:381304584260:web:458de3c51dd14a8b6fa411"
	}

	checkAuthentication(token: string) {
		// console.log("Checking Authentication", token);
		const headers = { 'Content-Type': 'application/json', 'Authorization': token }
		return axios.get(`${this.baseURL}auth/protected`, { headers: headers }).then((res: any) => {
			return res.data;
		});
	}

	login(social: any) {
		// console.log("Trying to call login here.", social)
		return axios.post(`${this.baseURL}auth/signIn/`, { userAuth: social }).then((res: any) => {
			return res.data;
		});
	}
}